const en = {
  SEO_DEFAULT_TITLE:
    "Safety App: More security and less accidents at your work place",
  SEO_DEFAULT_DESCRIPTION:
    "The Safety App offers various possibilities to prevent accidents and thus increase security to you and your coworkers.",

  CURRENT_LANGUAGE_NAME: "English",

  LINK_HOME_NAME: "Home",
  LINK_HOME_URL: "/en/",
  LINK_IMPRINT_NAME: "Imprint",
  LINK_IMPRINT_URL: "/en/impressum",
  LINK_TERMS_OF_USE_NAME: "Terms of use",
  LINK_TERMS_OF_USE_URL: "https://bruggsafety.com/en/terms-of-use",
  LINK_DATA_PRIVACY_NAME: "Data privacy",
  LINK_DATA_PRIVACY_URL: "https://bruggsafety.com/en/data-privacy",

  HERO_TITLE_PART_1: "More security and less accidents ",
  HERO_TITLE_PART_2: "using the Safety App",
  HERO_BODY:
    "The Safety App offers various possibilities to prevent accidents and thus increase security to you and your coworkers in security-relevant environments.",
  HERO_CTA: "Try it now!",
  HERO_IMAGE_ALT: "A happy team gives each other a high five",

  STORE_LINK_APPLE: "https://apps.apple.com/us/app/brugg-safety/id1617084542",
  STORE_LINK_GOOGLE:
    "https://play.google.com/store/apps/details?id=brugg.apps.safety",
  STORE_BADGE_APPLE_ALT: "Download Safety App on the App Store",
  STORE_BADGE_GOOGLE_ALT: "Download Safety App on Google Play",

  FEATURES_TITLE_TOP: "Features",
  FEATURES_TITLE_MAIN: "Four reasons to use the Safety App",
  FEATURES_DESCRIPTION: "Why you should use the app with your team.",
  FEATURE_1_NAME: "Report incidents intuitively",
  FEATURE_1_DESCRIPTION:
    "Errors, almost-accidents or property damage can be reported easily and fast. Photos, videos, voice recordings and the GPS position can be added to the report to make it more useful.",
  FEATURE_2_NAME: "Real-time collaboration",
  FEATURE_2_DESCRIPTION:
    "Every team member is able to report and manage incidents with their own smartphone or tablet.",
  FEATURE_3_NAME: "Storage in the cloud",
  FEATURE_3_DESCRIPTION:
    "Every report and security relevant document is securely stored in the cloud and can be accessed at every time and everywhere.",
  FEATURE_4_NAME: "Emnergency and dead man feeatures",
  FEATURE_4_DESCRIPTION:
    "People who are working alone can secure themselves by using the integrated emergency and dead man features.",

  PRICING_TITLE_TOP: "Pricing",
  PRICING_TITLE_MAIN: "Get the maximum out of your team",
  PRICING_TITLE_DESCRIPTION:
    "The pro version of Shotdok is for professional users who would like to use Shotdok with the whole team. It is possible to upgrade at any time from within the app.",
  PRICING_PERIOD: "year and team",
  PRICING_1_NAME: "Basic",
  PRICING_1_DESCRIPTION: "Basic version to give Shotdok a try.",
  PRICING_1_FEATURE_1: "Capture the physical sticky notes by photo or video",
  PRICING_1_FEATURE_2: "Enrich sticky notes with a voice memo or text",
  PRICING_1_FEATURE_3:
    "Search for old sticky notes and decisions in your teams",
  PRICING_1_FEATURE_4: "No possibility to work in teams",
  PRICING_1_FEATURE_5: "Limited to 20 sticky notes per team",
  PRICING_2_NAME: "Pro",
  PRICING_2_DESCRIPTION: "For professional users and teams.",
  PRICING_2_FEATURE_1: "Capture the physical sticky notes by photo or video",
  PRICING_2_FEATURE_2: "Enrich sticky notes with a voice memo or text",
  PRICING_2_FEATURE_3:
    "Search for old sticky notes and decisions in your teams",
  PRICING_2_FEATURE_4: "Unlimited team members",
  PRICING_2_FEATURE_5: "Unlimited sticky notes per team",

  CTA_TITLE_1: "Collaborate with your team - anytime and everywhere. ",
  CTA_TITLE_2: "Download the app today!",
  CTA_DESCRIPTION:
    "You can install the Safety App and make your work environment more safe instantly!",
  CTA_IMAGE_ALT: "Woman is excited about the Safety App",

  POSTER_DOWNLOAD_TITLE: "Kanban-Scrum framework poster",
  POSTER_DOWNLOAD_DESCRIPTION:
    "This poster shows how the Kanban-Scrum framework works with Shotdok and how it conforms the ISO standards. The blue and yellow numbers reference the two ISO standards. Download it now and pin it next to your Scrum or Kanban board!",
  POSTER_DOWNLOAD_A1_URL: "/Poster_Scrum_Kanban_A1_EN.pdf",
  POSTER_DOWNLOAD_A1_TEXT: "Poster in A1 format",
  POSTER_DOWNLOAD_A3_URL: "/Poster_Scrum_Kanban_A3_EN.pdf",
  POSTER_DOWNLOAD_A3_TEXT: "Poster in A3 format",
};

export default en;
