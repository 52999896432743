const de = {
  SEO_DEFAULT_TITLE:
    "Safety App: Mehr Sicherheit und weniger Unfälle am Arbeitsplatz",
  SEO_DEFAULT_DESCRIPTION:
    "Die Safety App bietet deinen Mitarbeitenden in sicherheitskritischen Umgebungen Möglichkeiten, um Unfälle zu vermeiden und so die Sicherheit zu erhöhen.",

  CURRENT_LANGUAGE_NAME: "Deutsch",

  LINK_HOME_NAME: "Startseite",
  LINK_HOME_URL: "/",
  LINK_IMPRINT_NAME: "Impressum",
  LINK_IMPRINT_URL: "/impressum",
  LINK_TERMS_OF_USE_NAME: "Nutzungsbedingungen",
  LINK_TERMS_OF_USE_URL: "https://bruggsafety.com/nutzungsbedingungen",
  LINK_DATA_PRIVACY_NAME: "Datenschutz",
  LINK_DATA_PRIVACY_URL: "https://bruggsafety.com/datenschutz",

  HERO_TITLE_PART_1: "Mehr Sicherheit und weniger Unfälle ",
  HERO_TITLE_PART_2: "mit der Safety App",
  HERO_BODY:
    "Die Safety App bietet dir und deinen Mitarbeitenden in sicherheitskritischen Umgebungen verschiedene Möglichkeiten, um Unfälle zu vermeiden und so die Sicherheit zu erhöhen.",
  HERO_CTA: "Jetzt ausprobieren!",
  HERO_IMAGE_ALT: "Ein glückliches Team gibt sich ein High-Five",

  STORE_LINK_APPLE: "https://apps.apple.com/de/app/brugg-safety/id1617084542",
  STORE_LINK_GOOGLE:
    "https://play.google.com/store/apps/details?id=brugg.apps.safety",
  STORE_BADGE_APPLE_ALT: "Safety App im App Store herunterladen",
  STORE_BADGE_GOOGLE_ALT: "Safety App bei Google Play herunterladen",

  FEATURES_TITLE_TOP: "Funktionen",
  FEATURES_TITLE_MAIN: "Vier Gründe für die Safety App",
  FEATURES_DESCRIPTION: "Warum du die App mit deinem Team verwenden solltest.",
  FEATURE_1_NAME: "Vorkomnisse intuitiv dokumentieren",
  FEATURE_1_DESCRIPTION:
    "Störungen, Beinahe-Unfälle oder Schäden werden schnell und einfach dokumentiert, wobei Bilder, Videos, Sprachmemos sowie die GPS-Position angefügt werden können.",
  FEATURE_2_NAME: "Zusammenarbeit in Echtzeit",
  FEATURE_2_DESCRIPTION:
    "Jedes Teammitglied kann mit dem eigenen Smartphone und Tablet Berichte erfassen und verwalten.",
  FEATURE_3_NAME: "Ablage in der Cloud",
  FEATURE_3_DESCRIPTION:
    "Sämtliche Berichte und sicherheitsrelevanten Dokumente werden sicher in der Cloud gespeichert und sind jederzeit von überall her verfügbar.",
  FEATURE_4_NAME: "Notruf- und Totmann-Funktion",
  FEATURE_4_DESCRIPTION:
    "Alleine arbeitende Personen können sich durch eine integrierte Notruf- und Totmann-Funktion absichern.",

  PRICING_TITLE_TOP: "Preise",
  PRICING_TITLE_MAIN: "Hole das Maximum aus deinem Team heraus",
  PRICING_TITLE_DESCRIPTION:
    "Die Pro Version von Shotdok richtet sich an professionelle Anwender*innen, die Shotdok mit dem ganzen Team verwenden möchten. Ein Upgrade ist jederzeit innerhalb der App durchführbar.",
  PRICING_PERIOD: "Jahr und Team",
  PRICING_1_NAME: "Basic",
  PRICING_1_DESCRIPTION: "Basisversion, um Shotdok zu testen.",
  PRICING_1_FEATURE_1: "Erfasse die physischen Merkzettel per Foto oder Video",
  PRICING_1_FEATURE_2: "Ergänze Merkzettel mit einer Sprachnotiz oder Text",
  PRICING_1_FEATURE_3:
    "Suche gezielt in deinen Teams nach alten Merkzetteln und Entscheidungen",
  PRICING_1_FEATURE_4: "Keine möglichkeit im Team zu arbeiten",
  PRICING_1_FEATURE_5: "Merkzettel pro Team sind auf 20 Stück begrenzt",
  PRICING_2_NAME: "Pro",
  PRICING_2_DESCRIPTION: "Für professionelle Anwender*innen und Teams.",
  PRICING_2_FEATURE_1: "Erfasse die physischen Merkzettel per Foto oder Video",
  PRICING_2_FEATURE_2: "Ergänze Merkzettel mit einer Sprachnotiz oder Text",
  PRICING_2_FEATURE_3:
    "Suche gezielt in deinen Teams nach alten Merkzetteln und Entscheidungen",
  PRICING_2_FEATURE_4: "Unlimitiert viele Mitglieder pro Team",
  PRICING_2_FEATURE_5: "Unlimitiert viele Merkzettel pro Team",

  CTA_TITLE_1: "Arbeite mit deinem Team zusammen - jederzeit und überall. ",
  CTA_TITLE_2: "Lade dir die App noch heute herunter!",
  CTA_DESCRIPTION:
    "Du kannst die Safety App installieren und dein Arbeitsplatz sofort sicherer machen!",
  CTA_IMAGE_ALT: "Eine Frau ist von der Safety App begeistert",

  POSTER_DOWNLOAD_TITLE: "Kanban-Scrum Framework Poster",
  POSTER_DOWNLOAD_DESCRIPTION:
    "Dieses Poster zeigt dir auf, wie das Kanban-Scrum Framework mit Shotdok funktioniert und wie es die ISO Normen erfüllt. Die blueen und gelben Zahlen verweisen dabei auf die jeweilige ISO Norm. Lade es dir herunter und hänge es neben dein Scrum oder Kanban Board!",
  POSTER_DOWNLOAD_A1_URL: "/Poster_Scrum_Kanban_A1_DE.pdf",
  POSTER_DOWNLOAD_A1_TEXT: "Poster im A1 Format",
  POSTER_DOWNLOAD_A3_URL: "/Poster_Scrum_Kanban_A3_DE.pdf",
  POSTER_DOWNLOAD_A3_TEXT: "Poster im A3 Format",
};

export default de;
