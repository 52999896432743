import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n";

import i18nDe from "../i18n/de";
import i18nEn from "../i18n/en";

import flagDe from "../images/flags/de.png";
import flagEn from "../images/flags/en.png";

const flagMap = new Map<string, any>();
flagMap.set("de", flagDe);
flagMap.set("en", flagEn);

const i18nMap = new Map<string, any>();
i18nMap.set("de", i18nDe);
i18nMap.set("en", i18nEn);

type LanguageKey = string;

interface Translation {
  languageKey: LanguageKey;
  url: string;
  flag: any;
  i18n: any;
}

interface LanguageInformation {
  currentLanguageKey: LanguageKey;
  currentUrl: string;
  translations: Translation[];
  currentTranslation: Translation;
}

const useLanguageInformation = (): LanguageInformation => {
  const [isSSR, setSSR] = React.useState<boolean>(true);

  React.useEffect(() => {
    setSSR(typeof window === "undefined");
  }, []);

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          languages {
            languageKeys
            defaultLanguageKey
          }
        }
      }
    }
  `);
  const { languageKeys, defaultLanguageKey } = data.site.siteMetadata.languages;

  const defaultUrl = "/";
  const currentUrl = isSSR ? defaultUrl : location.pathname;
  const currentLanguageKey = getCurrentLangKey(
    languageKeys,
    defaultLanguageKey,
    currentUrl
  );
  const homeLink = `/${currentLanguageKey}/`.replace(
    `/${defaultLanguageKey}/`,
    "/"
  );
  const translations = getLangs(
    languageKeys,
    currentLanguageKey,
    getUrlForLang(homeLink, currentUrl)
  ).map((item) => {
    const translationUrl: Translation = {
      languageKey: item.langKey,
      url: item.link.replace(`/${defaultLanguageKey}/`, "/"),
      flag: flagMap.get(item.langKey),
      i18n: i18nMap.get(item.langKey),
    };
    return translationUrl;
  });
  const currentTranslation = translations.find(
    (t) => t.languageKey === currentLanguageKey
  );

  return {
    currentLanguageKey,
    currentUrl,
    translations,
    currentTranslation,
  };
};

export default useLanguageInformation;
