import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "react-intl";
import useLanguageInformation from "../hooks/useLanguageInformation";

import safetyAppOgImage from "../images/safety-app-og.png";

interface Props {
  title?: string;
  description?: string;
}

const SEO: React.FC<Props> = ({ title, description }) => {
  const intl = useIntl();
  const languageInformation = useLanguageInformation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const defaultTitle = intl.formatMessage({ id: "SEO_DEFAULT_TITLE" });
  const defaultDescription = intl.formatMessage({
    id: "SEO_DEFAULT_DESCRIPTION",
  });

  const metaDescription = description || defaultDescription;

  const imageUrl = `${site.siteMetadata.siteUrl}${safetyAppOgImage}`;

  const pageTitle = title || defaultTitle;
  const titleAppendix = " | Safety App";
  const shouldAppendAppendix = pageTitle.length <= 65 - titleAppendix.length;
  const titleTemplate = shouldAppendAppendix ? `%s${titleAppendix}` : "%s";

  return (
    <Helmet titleTemplate={titleTemplate}>
      <html lang={languageInformation.currentLanguageKey} />
      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:url" content={languageInformation.currentUrl} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
  );
};

export default SEO;
